import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactFloaterJs from "./Animations/Floater/ReactFloaterJS";
import AOS from "aos";
import "aos/dist/aos.css";

const Description = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <Container id="description">
        <h1>How it works</h1>
        <p style={{ marginBottom: "-2%" }}>
          Track all of your NFTs in one place. Add multiple wallets and view
          your NFTs and Collections quickly and easily. Discover new NFT
          collections and socialize with Web3 friends.
        </p>
        <ReactFloaterJs>
          <picture>
            <source
              srcSet="/description_icons/mobile-compressed.webp"
              type="image/webp"
            />
            <source
              srcSet="/description_icons/mobile-compressed.png"
              type="image/png"
            />
            <img
              src="/description_icons/mobile-compressed.png"
              alt="Alt Text!"
              loading="lazy"
              style={{ width: "100%" }}
              className="mobileImage"
              data-aos="slide-up"
            />
          </picture>
          {/* <Image
            src=""
            style={{ width: "100%" }}
            className="mobileImage"
            data-aos="slide-up"
            loading="lazy"
          /> */}
        </ReactFloaterJs>

        <Row
          className="justify-content-between"
          style={{ marginTop: "-10%", zIndex: "1" }}
        >
          <Col
            xs={12}
            md={6}
            className="my-3 description-columns "
            data-aos="zoom-in"
            // data-aos-delay="200"
          >
            <Row>
              <Col xs={2}>
                {/* <Image src="description_icons/wallet.png" className="w-100" /> */}
                <picture>
                  <source
                    srcSet="description_icons/wallet.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="description_icons/wallet.png"
                    type="image/png"
                  />
                  <img
                    src="description_icons/wallet.png"
                    className="w-100"
                    alt=""
                  />
                </picture>
              </Col>
              <Col xs={10}>
                <h5>Track Wallets</h5>
                <small>
                  Add one or multiple wallets and track their NFTs with
                  up-to-date Floor Prices. View your entire wallet worth in ETH.
                  All you need is a wallet address to track any wallet.
                </small>
              </Col>
            </Row>
          </Col>
          {/* <Col md={1}></Col> */}
          <Col
            xs={12}
            md={6}
            className="my-3 description-columns "
            data-aos="zoom-in"
            // data-aos-delay="200"
          >
            <Row>
              <Col xs={2}>
                {/* <Image
                  src="description_icons/chart.png"
                  className="w-100"
                  loading="lazy"
                /> */}

                <picture>
                  <source
                    srcSet="description_icons/chart.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="description_icons/chart.png"
                    type="image/png"
                  />
                  <img
                    src="description_icons/chart.png"
                    className="w-100"
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </Col>
              <Col xs={10}>
                <h5>NFT Stats</h5>
                <small>
                  Discover and track various NFT collections and their stats.
                  Get Floor Prices and Volume for top collections and stay
                  informed about new drops.
                </small>
              </Col>
            </Row>
          </Col>

          <Col
            md={6}
            className="my-3 description-columns"
            data-aos="zoom-in"
            // data-aos-delay="200"
          >
            <Row>
              <Col xs={2}>
                {/* <Image
                  src="description_icons/Dashboard.png"
                  className="w-100"
                /> */}
                <picture>
                  <source
                    srcSet="description_icons/Dashboard.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="description_icons/Dashboard.png"
                    type="image/png"
                  />
                  <img
                    src="description_icons/Dashboard.png"
                    className="w-100"
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </Col>
              <Col xs={10}>
                <h5>Setup Your Web3 Profile</h5>
                <small>
                  Create your Web3 Profile by adding specific NFTs to your
                  profile. Share your profile with others to view your favorite
                  NFTs.
                </small>
              </Col>
            </Row>
          </Col>
          <Col
            md={6}
            className="my-3 description-columns"
            data-aos="zoom-in"
            // data-aos-delay="200"
          >
            <Row>
              <Col xs={2}>
                {/* <Image src="description_icons/menu.png" className="w-100" /> */}
                <picture>
                  <source
                    srcSet="description_icons/menu.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="description_icons/menu.png"
                    type="image/png"
                  />
                  <img
                    src="description_icons/menu.png"
                    className="w-100"
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </Col>
              <Col xs={10}>
                <h5>NFT News</h5>
                <small>
                  Know what's happening as it happens. We curate the best and
                  most important news across various topics. Stay on top of the
                  NFT space daily.
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Description;
