import "bootstrap/dist/css/bootstrap.min.css";
import Screenshots from "./Components/Screenshots";
import Description from "./Components/Description";
import Top from "./Components/Top";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Modal from "./Components/Animations/Modal/Modal";
import Misc from "./Components/Misc";
import AOS_demo from "./Components/Animations/aos_animation/AOS_demo";
import FloaterDemo from "./Components/Animations/Floater/FloaterDemo";
import Base from "./Components/Animations/Pizza/Base";
import LightboxExample from "./Components/Animations/Lightbox/Lightbox";
import Video from "./Components/Video";
import AOS from "aos";
import Deck from "./Components/Animations/CardGesture/CardGesture";
import DraggableList from "./Components/Animations/DraggableList/DraggableList";
import DragAnywhereContainer from "./Components/Animations/DragAnywhere/DragAnywhereContainer";
import "./App.css";
import ParallaxScroll from "./Components/Animations/ParallaxScroll/ParallaxScroll";

AOS.init({
  duration: 1000,
});
setTimeout(() => AOS.refresh(), 1000);
// AOS.refresh();

function App() {
  return (
    <div className="App d-flex flex-column min-vh-100">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Video />
                <div id="top_container">
                  <Top />
                  <Screenshots />
                  <Description />
                  {/* <Featured /> */}
                  <Misc />
                </div>
              </>
            }
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/animation1" element={<Modal />} />

          <Route
            path="/animation2"
            element={
              // eslint-disable-next-line
              <AOS_demo />
            }
          />

          <Route path="/animation3" element={<FloaterDemo />} />

          <Route path="/animation4" element={<Base />} />

          <Route path="/animation5" element={<LightboxExample />} />

          <Route path="/animation6" element={<Deck />} />

          <Route path="/animation7" element={<DraggableList />} />

          <Route path="/animation8" element={<DragAnywhereContainer />} />

          <Route path="/animation9" element={<ParallaxScroll />} />
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
