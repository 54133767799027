import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./AOS_demo.css";
const AOS_demo = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <div className="aos-item aos-left" data-aos="fade-up">
        <p>1</p>
      </div>
      <div className="aos-item aos-right" data-aos="fade-down">
        <p>2</p>
      </div>
      <div className="aos-item aos-left" data-aos="fade-right">
        <p>3</p>
      </div>
      <div className="aos-item aos-right" data-aos="fade-left">
        <p>4</p>
      </div>

      <div className="aos-item aos-left" data-aos="zoom-in">
        <p>5</p>
      </div>
      <div className="aos-item aos-right" data-aos="zoom-out">
        <p>6</p>
      </div>

      <div className="aos-item aos-left" data-aos="slide-up">
        <p>7</p>
      </div>

      <div className="aos-item aos-right" data-aos="flip-up">
        <p>8</p>
      </div>
      <div className="aos-item aos-left" data-aos="flip-down">
        <p>9</p>
      </div>
      <div className="aos-item aos-right" data-aos="flip-right">
        <p>10</p>
      </div>
      <div className="aos-item aos-left" data-aos="flip-left">
        <p>11</p>
      </div>
      <div className="aos-item aos-right" data-aos="zoom-out-down">
        <p>12</p>
      </div>
    </div>
  );
};

export default AOS_demo;
