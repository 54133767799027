import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import Modal_Box from "./Animations/Modal/Modal_Box";
// import AOS from "aos";

const Misc = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  return (
    <Container className="text-center">
      <h1>Have a Question?</h1>

      <motion.button
        className="save-button"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => (modalOpen ? close() : open())}
      >
        Contact Us
      </motion.button>
      <AnimatePresence
        // Disable any initial animations on children that are present when the component is first rendered
        initial={false}
        // Only render one component at a time. The exiting component will finish its exit animation before entering component is rendered
        exit={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {
          // eslint-disable-next-line
          modalOpen && <Modal_Box modalOpen={modalOpen} handleClose={close} />
        }
      </AnimatePresence>

      {/* <div className="my-4">
        <h1>Animation Libraries Templates</h1>
        <Link to="/animation1" className="mx-1">
          <Button>Animation 1</Button>
        </Link>
        <Link to="/animation2" className="mx-1">
          <Button>Animation 2</Button>
        </Link>
        <Link to="/animation3" className="mx-1">
          <Button>Animation 3</Button>
        </Link>
        <Link to="/animation4" className="mx-1">
          <Button>Animation 4</Button>
        </Link>
      </div> */}
    </Container>
  );
};

export default Misc;
