import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import ScrollContainer from "react-indiana-drag-scroll";

const Screenshots = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const ss = [
    "screenshots/compressed/1-min.jpg",
    "screenshots/compressed/2-min.jpg",
    "screenshots/compressed/5-min.jpg",
    "screenshots/compressed/6-min.jpg",
    "screenshots/compressed/10-min.jpg",
  ];

  const webpss = [
    "screenshots/webp/1-min.webp",
    "screenshots/webp/2-min.webp",
    "screenshots/webp/5-min.webp",
    "screenshots/webp/6-min.webp",
    "screenshots/webp/10-min.webp",
  ];
  useEffect(() => {
    console.log("photoIndex:", photoIndex);
  }, [photoIndex]);

  return (
    <div>
      <Container id="screenshots">
        <h1>Preview</h1>

        <ScrollContainer className="scroll-container screenshots-scroll">
          <Row className="flex-nowrap" md={5}>
            <Col
              // md={3}
              xs={5}
              sm={5}
              className="position-relative"
              onClick={() => {
                setIsLightboxOpen(true);
                setPhotoIndex(0);
                // console.log("0 clicked");
              }}
            >
              {/* <Image className="zoom__icon" src="zoom_icon.png" ></Image> */}
              {/* <Image src={ss[0]} /> */}
              <picture>
                <source srcSet={webpss[0]} type="image/webp" />
                <source srcSet={ss[0]} type="image/jpeg" />
                <img src={ss[0]} alt="Alt Text!" loading="lazy" />
              </picture>
            </Col>
            <Col
              // md={3}
              xs={5}
              sm={5}
              className="position-relative"
              onClick={() => {
                setIsLightboxOpen(true);
                setPhotoIndex(1);
                // console.log("1 clicked");
              }}
            >
              {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
              {/* <Image src={ss[1]} /> */}
              <picture>
                <source srcSet={webpss[1]} type="image/webp" />
                <source srcSet={ss[1]} type="image/jpeg" />
                <img src={ss[1]} alt="Alt Text!" loading="lazy" />
              </picture>
            </Col>
            <Col
              // md={3}
              sm={5}
              xs={5}
              className="position-relative"
              onClick={() => {
                setIsLightboxOpen(true);
                setPhotoIndex(2);
                // console.log("2 clicked");
              }}
            >
              {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
              {/* <Image src={ss[2]} /> */}
              <picture>
                <source srcSet={webpss[2]} type="image/webp" />
                <source srcSet={ss[2]} type="image/jpeg" />
                <img src={ss[2]} alt="Alt Text!" loading="lazy" />
              </picture>
            </Col>
            <Col
              // md={3}
              sm={5}
              xs={5}
              className="position-relative"
              onClick={() => {
                setIsLightboxOpen(true);
                setPhotoIndex(3);
                // console.log("3 clicked");
              }}
            >
              {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
              {/* <Image src={ss[3]} /> */}
              <picture>
                <source srcSet={webpss[3]} type="image/webp" />
                <source srcSet={ss[3]} type="image/jpeg" />
                <img src={ss[3]} alt="Alt Text!" loading="lazy" />
              </picture>
            </Col>
            <Col
              // md={3}
              sm={5}
              xs={5}
              className="position-relative"
              onClick={() => {
                setIsLightboxOpen(true);
                setPhotoIndex(4);
                // console.log("4 clicked");
              }}
            >
              {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
              {/* <Image src={ss[4]} /> */}
              <picture>
                <source srcSet={webpss[4]} type="image/webp" />
                <source srcSet={ss[4]} type="image/jpeg" />
                <img src={ss[4]} alt="Alt Text!" loading="lazy" />
              </picture>
            </Col>
          </Row>
        </ScrollContainer>
      </Container>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={ss[photoIndex]}
          nextSrc={ss[(photoIndex + 1) % ss.length]}
          prevSrc={ss[(photoIndex + ss.length - 1) % ss.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() => {
            setPhotoIndex((photoIndex + ss.length - 1) % ss.length);
            console.log("photoindex updated to", photoIndex);
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % ss.length);
            console.log("photoindex updated to", photoIndex);
          }}
          imagePadding={52}
          animationOnKeyInput={true}
        />
      )}
    </div>
  );
};

export default Screenshots;
