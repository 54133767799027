import React from "react";

export const Footer = () => {
  let footerStyle = {
    color: "lightgray",
    // fontSize: ".8rem",
    // letterSpacing: "1px",
    // position: "fixed",
    // bottom: "0",
    // width: "100%",
  };
  return (
    <footer className="bg-dark fixed text-center mt-auto" style={footerStyle}>
      <small style={footerStyle}>
        2022{" "}
        <a
          style={{ color: "inherit", textDecoration: "none" }}
          href="http://www.developingnow.com"
        >
          DevelopingNow, Inc.
        </a>
        <br />
        <span className="d-inline"> All Rights Reserved </span>
      </small>
    </footer>
  );
};

export default Footer;
