import React from "react";
import { Form, Container } from "react-bootstrap";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div className="about">
      <Container className="about_container">
        <h1 className="text-center">Contact Us</h1>
        <Form className="text-start">
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control placeholder="" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" />
            <Form.Text className="text-muted" style={{ fontSize: "0.6rem" }}>
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Your Message</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
          {/* <button
            className="submit-button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Submit
          </button> */}
          <p className="text-center mb-0">
            <motion.button
              className="save-button"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={(e) => e.preventDefault()}
            >
              Submit
            </motion.button>
          </p>
        </Form>
      </Container>
    </div>
  );
};

export default Contact;
