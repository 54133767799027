import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "./../scss/modal-video.scss";

const Video = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <ModalVideo
          // channel="youtube"
          // videoId="L61p2uyiMSo"
          channel="custom"
          url="https://d1pjakk9p5804f.cloudfront.net/NFTs_Introduction_Video.mp4"
          ratio="9:16"
          autoplay
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
        {/* <Image
          src="play.png"
          className="video-play"
          onClick={() => setOpen(true)}
          loading="lazy"
        /> */}
        <picture>
          <source srcSet="play.webp" type="image/webp" />
          <source srcSet="play.png" type="image/png" />
          <img
            src="play.png"
            className="video-play"
            alt=""
            onClick={() => setOpen(true)}
          />
        </picture>
        {/* <Image
          src="thumbnail-min.png"
          className="video-thumbnail w-100"
          loading="lazy"
        /> */}
        <picture>
          <source srcSet="thumbnail-min.webp" type="image/webp" />
          <source srcSet="thumbnail-min.png" type="image/png" />
          <img
            src="thumbnail-min.png"
            className="video-thumbnail w-100"
            alt=""
          />
        </picture>
      </div>
    </React.Fragment>
  );
};

export default Video;
