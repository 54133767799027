import React from "react";
// import ReactDOM from 'react-dom'
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
// import "./styles.css";

export default function DragAnywhere({ children }) {
  const [props, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    touchAction: "none",

    // marginTop: "15%",
  }));
  const bind = useDrag(({ event, active, movement: [x, y] }) => {
    event.preventDefault();
    api.start({
      x: active ? x : 0,
      y: active ? y : 0,
      scale: active ? 1.2 : 1,
      immediate: (k) => k !== "scale" && active,
      cursor: "pointer",
    });
  });
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    // <div style={{ display: "flex", justifyContent: "center" }}>
    <animated.div {...bind()} style={props}>
      {children}
    </animated.div>
    // </div>
  );
}
