import React, { useEffect, useRef, useState } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import "../ParallaxScroll/ParallaxScroll.scss";
import ScrollContainer from "react-indiana-drag-scroll";
import { Col, Row } from "react-bootstrap";
// Little helpers ...
const url = (name, wrap = false) =>
  `${
    wrap ? "url(" : ""
  }https://awv3node-homepage.surge.sh/build/assets/${name}.svg${
    wrap ? ")" : ""
  }`;

export default function ParallaxScroll() {
  const parallax = useRef();

  const [isMobile, setMobile] = useState(
    window.screen.orientation.type === "portrait-primary"
  );

  const updateScreenSize = () => {
    setMobile(window.screen.orientation.type === "portrait-primary");
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  });

  const descriptionLayerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  // const descriptionDiv = {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   color: "white",
  //   width: "50%",
  //   background: "rgb(255,255,255,0.1)",
  //   borderRadius: "20px",
  //   padding: "0 60px",
  // };

  const ss = [
    "screenshots/compressed/TrackA.jpg",
    "screenshots/compressed/TrackB.jpg",
    "screenshots/compressed/Home.jpg",
    "screenshots/compressed/Manage.jpg",
    "screenshots/compressed/Details.jpg",
  ];

  const webpss = [
    "screenshots/webp/TrackA.webp",
    "screenshots/webp/TrackB.webp",
    "screenshots/webp/Home.webp",
    "screenshots/webp/Manage.webp",
    "screenshots/webp/Details.webp",
  ];

  return (
    <div style={{ width: "100%", height: "100%", color: "white" }}>
      <Parallax ref={parallax} pages={6}>
        <div style={{ background: "#000917", height: "600vh" }} />
        {/* <ParallaxLayer
        offset={0}
        speed={1}
        // style={{ backgroundColor: "#111111" }}
  />*/}

        {/* <ParallaxLayer
        offset={1}
        speed={1}
        style={{ backgroundColor: "#123111" }}
      /> */}

        {/*
      <ParallaxLayer
        offset={2}
        speed={1}
        // style={{ backgroundColor: "#000000" }}
      />
      <ParallaxLayer
        offset={3}
        speed={1}
        // style={{ backgroundColor: "#000000" }}
      />
      <ParallaxLayer
        offset={4}
        speed={1}
        // style={{ backgroundColor: "#111111" }}
      />
      <ParallaxLayer
        offset={5}
        speed={1}
        // style={{ backgroundColor: "#000000" }}
      /> */}

        <ParallaxLayer
          offset={0}
          speed={1}
          factor={4}
          style={{
            backgroundImage: url("stars", true),
            backgroundSize: "cover",
          }}
        />

        <ParallaxLayer
          offset={2}
          speed={1}
          factor={4}
          style={{
            backgroundImage: url("stars", true),
            backgroundSize: "cover",
          }}
        />

        <ParallaxLayer
          offset={4}
          speed={1}
          factor={4}
          style={{
            backgroundImage: url("stars", true),
            backgroundSize: "cover",
          }}
        />

        {/* <ParallaxLayer
          offset={1.3}
          speed={-0.3}
          style={{ pointerEvents: "none" }}
        >
         

          <div style={{ width: "15%", marginInline: "auto", opacity: "0.4" }}>
            <h2>Track your NFTs</h2>
          </div>
        </ParallaxLayer> */}

        <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "55%" }}
            alt=""
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "10%", marginLeft: "15%" }}
            alt=""
          />
        </ParallaxLayer>

        <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.1 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "70%" }}
            alt=""
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "40%" }}
            alt=""
          />
        </ParallaxLayer>

        {/* <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          src={url("cloud")}
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
          alt=""
        />
        <img
          src={url("cloud")}
          style={{ display: "block", width: "20%", marginLeft: "75%" }}
          alt=""
        />
      </ParallaxLayer> */}

        <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.1 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "60%" }}
            alt=""
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "25%", marginLeft: "30%" }}
            alt=""
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "10%", marginLeft: "80%" }}
            alt=""
          />
        </ParallaxLayer>

        <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.1 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "5%" }}
            alt=""
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "15%", marginLeft: "75%" }}
            alt=""
          />
        </ParallaxLayer>

        <ParallaxLayer
          offset={4}
          // sticky={{ start: 4, end: 5 }}
          speed={-0.6}
          style={{ opacity: 0.1 }}
        >
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "5%" }}
            alt=""
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "15%", marginLeft: "75%" }}
            alt=""
          />
        </ParallaxLayer>

        <ParallaxLayer offset={5.2} speed={-0.4} style={{ opacity: 0.1 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "15%", marginLeft: "15%" }}
            alt=""
          />
        </ParallaxLayer>
        {/* 
        <ParallaxLayer
          offset={2.5}
          // sticky={{ start: 2.5, end: 4.5 }}
          speed={-0.4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <img src={url("earth")} style={{ width: "60%" }} alt="" />
        </ParallaxLayer> */}
        {/* 
      <ParallaxLayer
        offset={2}
        // sticky={{ start: 2, end: 5 }}
        speed={-0.3}
        style={{
          backgroundSize: "80%",
          backgroundPosition: "center",
          backgroundImage: url("clients", true),
        }}
      /> */}

        <ParallaxLayer
          offset={0}
          speed={0.1}
          onClick={() => parallax.current.scrollTo(1)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <img
            src={"anzali-app-icon.png"}
            className="anzali-app-icon"
            // style={{ width: "20%", position: "relative", bottom: "60px" }}
            alt=""
          />
          {/* <p style={{ width: "40%", textAlign: "center", margin: "15px 0" }}>
          Track all of your NFTs in one place. Add multiple wallets and view
          your NFTs and Collections quickly and easily. Discover new NFT
          collections and socialize with frens.
        </p> */}

          <picture
            style={{ textAlign: "center", position: "absolute", bottom: "0" }}
          >
            <p style={{ color: "#ffc60b", textShadow: "2px 2px 5px white" }}>
              Discover & Track NFTs
            </p>
            <img
              src="scroll-down-icon.webp"
              alt=""
              style={{ width: "50%" }}
              onClick={() => parallax.current.scrollTo(1)}
            />
          </picture>
        </ParallaxLayer>

        {isMobile ? (
          <ParallaxLayer
            offset={1}
            speed={0.3}
            factor={1}
            onClick={() => parallax.current.scrollTo(2)}
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-around",
              padding: "40px",
              // position: "relative",
            }}
          >
            {/* <h1
              style={{
                position: "absolute",
                background: "black",
                top: "25px",
                width: "100%",
              }}
            >
              <img
                src="left-right-arrow-icon.jpg"
                alt=""
                style={{
                  width: "100%",
                  height: "70px",
                }}
              />
            </h1> */}

            <ScrollContainer className="scroll-container screenshots-scroll ">
              <Row className="flex-nowrap">
                <Col
                  xs={8}
                  className="position-relative"
                  onClick={() => {
                    // setIsLightboxOpen(true);
                    // setPhotoIndex(0);
                    // console.log("0 clicked");
                  }}
                >
                  {/* <Image className="zoom__icon" src="zoom_icon.png" ></Image> */}
                  {/* <Image src={ss[0]} /> */}
                  <picture>
                    <source srcSet={webpss[0]} type="image/webp" />
                    <source srcSet={ss[0]} type="image/jpeg" />
                    <img
                      src={ss[0]}
                      alt="Alt Text!"
                      loading="lazy"
                      style={{ width: "100%", borderRadius: "30px" }}
                    />
                  </picture>
                </Col>
                <Col
                  // md={3}
                  xs={8}
                  className="position-relative"
                  onClick={() => {
                    // setIsLightboxOpen(true);
                    // setPhotoIndex(1);
                    // console.log("1 clicked");
                  }}
                >
                  {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
                  {/* <Image src={ss[1]} /> */}
                  <picture>
                    <source srcSet={webpss[1]} type="image/webp" />
                    <source srcSet={ss[1]} type="image/jpeg" />
                    <img
                      src={ss[1]}
                      alt="Alt Text!"
                      loading="lazy"
                      style={{ width: "100%", borderRadius: "30px" }}
                    />
                  </picture>
                </Col>
                <Col
                  // md={3}
                  xs={8}
                  className="position-relative"
                  onClick={() => {
                    // setIsLightboxOpen(true);
                    // setPhotoIndex(2);
                    // console.log("2 clicked");
                  }}
                >
                  {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
                  {/* <Image src={ss[2]} /> */}
                  <picture>
                    <source srcSet={webpss[2]} type="image/webp" />
                    <source srcSet={ss[2]} type="image/jpeg" />
                    <img
                      src={ss[2]}
                      alt="Alt Text!"
                      loading="lazy"
                      style={{ width: "100%", borderRadius: "30px" }}
                    />
                  </picture>
                </Col>
                <Col
                  // md={3}
                  xs={8}
                  className="position-relative"
                  onClick={() => {
                    // setIsLightboxOpen(true);
                    // setPhotoIndex(3);
                    // console.log("3 clicked");
                  }}
                >
                  {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
                  {/* <Image src={ss[3]} /> */}
                  <picture>
                    <source srcSet={webpss[3]} type="image/webp" />
                    <source srcSet={ss[3]} type="image/jpeg" />
                    <img
                      src={ss[3]}
                      alt="Alt Text!"
                      loading="lazy"
                      style={{ width: "100%", borderRadius: "30px" }}
                    />
                  </picture>
                </Col>
                <Col
                  // md={3}
                  xs={8}
                  className="position-relative"
                  onClick={() => {
                    // setIsLightboxOpen(true);
                    // setPhotoIndex(4);
                    // console.log("4 clicked");
                  }}
                >
                  {/* <Image className="zoom__icon" src="zoom_icon.png"></Image> */}
                  {/* <Image src={ss[4]} /> */}
                  <picture>
                    <source srcSet={webpss[4]} type="image/webp" />
                    <source srcSet={ss[4]} type="image/jpeg" />
                    <img
                      src={ss[4]}
                      alt="Alt Text!"
                      loading="lazy"
                      style={{ width: "100%", borderRadius: "30px" }}
                    />
                  </picture>
                </Col>
              </Row>
            </ScrollContainer>
            <picture
              style={{ textAlign: "center", position: "absolute", bottom: "0" }}
            >
              <img
                src="scroll-down-icon.webp"
                alt=""
                style={{ width: "50%" }}
              />
            </picture>
          </ParallaxLayer>
        ) : (
          <>
            <ParallaxLayer
              offset={1}
              speed={0.3}
              // onClick={() => parallax.current.scrollTo(2)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {/* <img src={url("bash")} style={{ width: "40%" }} alt="" /> */}
              <img
                src="screenshots/compressed/TrackA.jpg"
                style={{ width: "15%", marginRight: "2%" }}
                alt=""
              />
              <img
                src="screenshots/compressed/Home.jpg"
                style={{ width: "15%", marginRight: "2%" }}
                alt=""
              />
              <img
                src="screenshots/compressed/Details.jpg"
                style={{ width: "15%", marginRight: "2%" }}
                alt=""
              />
              {/* <img
            src="screenshots/compressed/Home.jpg"
            style={{ width: "15%", marginRight: "2%" }}
            alt=""
          />
          <img
            src="screenshots/compressed/Manage.jpg"
            style={{ width: "15%" }}
            alt=""
          /> */}
              <picture
                style={{
                  textAlign: "center",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <img
                  src="scroll-down-icon.webp"
                  alt=""
                  style={{ width: "50%" }}
                />
              </picture>
            </ParallaxLayer>
            <ParallaxLayer
              offset={1}
              speed={0.1}
              onClick={() => parallax.current.scrollTo(2)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "24.9%",
                marginRight: "27%",
                width: "unset",
              }}
            >
              {/* <img src={url("bash")} style={{ width: "40%" }} alt="" /> */}

              <img
                src="screenshots/compressed/TrackB.jpg"
                style={{ width: "31%" }}
                alt=""
              />
              <img
                src="screenshots/compressed/Manage.jpg"
                style={{ width: "31%" }}
                alt=""
              />
            </ParallaxLayer>
          </>
        )}

        <ParallaxLayer
          offset={2}
          speed={0.1}
          style={descriptionLayerStyle}
          onClick={() => parallax.current.scrollTo(3)}
        >
          <div className="description-div">
            <img
              src="description_icons/chart.png"
              alt=""
              style={{ marginBlock: "20px 1rem" }}
            />
            <h2>NFT stats</h2>

            <p className="text-center">
              Discover and track various NFT collections and their stats. Get
              Floor Prices and Volume for top collections and stay informed
              about new drops.
            </p>
          </div>
          <picture
            style={{ textAlign: "center", position: "absolute", bottom: "0" }}
          >
            <img src="scroll-down-icon.webp" alt="" style={{ width: "50%" }} />
          </picture>
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          // sticky={{ start: 2, end: 5 }}
          speed={0.1}
          style={descriptionLayerStyle}
          onClick={() => parallax.current.scrollTo(4)}
        >
          {/* <img src={url("clients-main")} style={{ width: "40%" }} alt="" /> */}
          <div className="description-div">
            <img
              src="description_icons/Dashboard.png"
              alt=""
              style={{ marginBlock: "20px 1rem" }}
            />
            <h2>Setup Your Web3 Profile</h2>
            <p className="text-center">
              Create your Web3 Profile by adding specific NFTs to your profile.
              Share your profile with others to view your favorite NFTs.
            </p>
          </div>
          <picture
            style={{ textAlign: "center", position: "absolute", bottom: "0" }}
          >
            <img src="scroll-down-icon.webp" alt="" style={{ width: "50%" }} />
          </picture>
        </ParallaxLayer>

        <ParallaxLayer
          offset={4}
          speed={0.01}
          style={descriptionLayerStyle}
          onClick={() => parallax.current.scrollTo(5)}
        >
          <div className="description-div">
            <img
              src="description_icons/menu.png"
              alt=""
              style={{ marginBlock: "20px 1rem" }}
            />
            <h2>NFT news</h2>
            <p className="text-center">
              Know what's happening as it happens. We curate the best and most
              important news across various topics. Stay on top of the NFT space
              daily.
            </p>
          </div>
          <picture
            style={{ textAlign: "center", position: "absolute", bottom: "0" }}
          >
            <img src="scroll-down-icon.webp" alt="" style={{ width: "50%" }} />
          </picture>
        </ParallaxLayer>

        <ParallaxLayer
          offset={5}
          speed={0.1}
          style={descriptionLayerStyle}
          onClick={() => parallax.current.scrollTo(0)}
        >
          <div className="description-div">
            <img
              src="description_icons/wallet.png"
              alt=""
              style={{ marginBlock: "20px 1rem" }}
            />
            <h2>Track Wallets</h2>
            <p className="text-center">
              Add one or multiple wallets and track their NFTs with up-to-date
              Floor Prices. View your entire wallet worth in ETH. All you need
              is a wallet address to track any wallet.
            </p>
          </div>
          <picture
            style={{ textAlign: "center", position: "absolute", bottom: "0" }}
          >
            <img
              src="scroll-up-icon.png"
              alt=""
              style={{ width: "7%", marginBottom: "15px" }}
            />
            <p>Go to top</p>
          </picture>
        </ParallaxLayer>
        <ParallaxLayer
          offset={6}
          speed={0.1}
          style={{ height: "1vh" }}
          onClick={() => parallax.current.scrollTo(0)}
        >
          <div style={{ height: "1px" }} />
        </ParallaxLayer>
      </Parallax>
    </div>
  );
}
