import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./modal.css";
import Modal_Box from "./Modal_Box";

const Modal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  return (
    <div className="d-flex justify-content-center">
      <motion.button
        className="save-button"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => (modalOpen ? close() : open())}
      >
        Launch Modal
      </motion.button>

      <AnimatePresence
        // Disable any initial animations on children that are present when the component is first rendered
        initial={false}
        // Only render one component at a time. The exiting component will finish its exit animation before entering component is rendered
        exit={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {
          // eslint-disable-next-line
          modalOpen && <Modal_Box modalOpen={modalOpen} handleClose={close} />
        }
      </AnimatePresence>
    </div>
  );
};

export default Modal;
