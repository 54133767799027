import React from "react";
import DragAnywhere from "./DragAnywhere";

const DragAnywhereContainer = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <DragAnywhere
        children={
          <img
            src="/description_icons/mobile.png"
            alt=""
            style={{ width: "250px" }}
          />
        }
      />
    </div>
  );
};

export default DragAnywhereContainer;
