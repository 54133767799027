import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ReactFloaterJs from "./Animations/Floater/ReactFloaterJS";
import AOS from "aos";
// import { Link } from "react-router-dom";
import DragAnywhere from "./Animations/DragAnywhere/DragAnywhere";
import { Link } from "react-router-dom";

const Top = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);
  const google_link =
    "https://play.google.com/store/apps/details?id=com.nftchills";
  const ios_link = "https://apps.apple.com/us/app/anzali/id1604822464";
  return (
    <div>
      <div className="container d-flex justify-content-center align-items-center">
        <div className="app__info col-sm-10 mx-auto">
          <Row className="align-items-center justify-content-around">
            <Col sm={3} xs={5} className="text-center">
              <Row>
                <Col>
                  <ReactFloaterJs>
                    <DragAnywhere
                      style={{ marginTop: "none" }}
                      children={
                        <picture>
                          <source
                            srcSet="anzali-app-icon.webp"
                            type="image/webp"
                          />
                          <source
                            srcSet="anzali-app-icon.png"
                            type="image/png"
                          />
                          <img
                            className="logo"
                            src="anzali-app-icon.png"
                            alt="Anzali app Icon"
                            fluid
                            data-aos="zoom-out"
                          />
                        </picture>
                      }
                    />
                  </ReactFloaterJs>
                </Col>
              </Row>
            </Col>
            {/* <Col sm={1}></Col> */}
            <Col sm={4} lg={3} xs={7} className="app__info__text text-start">
              {/* <Row className="align-items-center app__info__text text-start"> */}
              <h1>ANZALI</h1>
              <p style={{ marginLeft: "2px" }}>Discover and Track Your NFTs</p>
            </Col>
            {/* <Col sm={1}></Col> */}

            <Col sm={5} xs={12}>
              <Row className="justify-content-center align-items-center mt-3">
                <Col
                  xs={6}
                  className="store__button text-center"
                  // style={{ width: "52%" }}
                >
                  <a href={google_link} target="_blank" rel="noreferrer">
                    {/* <Image src="playstore.png" rounded /> */}
                    <picture>
                      <source srcSet="playstore.webp" type="image/webp" />
                      <source srcSet="playstore.png" type="image/png" />
                      <img
                        // className="rounded"
                        src="playstore.png"
                        loading="lazy"
                        alt=""
                      />
                    </picture>
                  </a>
                </Col>
                <Col
                  xs={6}
                  className="store__button text-center"
                  // style={{ width: "47%" }}
                >
                  <a href={ios_link} target="_blank" rel="noreferrer">
                    <picture>
                      <source srcSet="appstore.webp" type="image/webp" />
                      <source srcSet="appstore.png" type="image/png" />
                      <img
                        // className="rounded"
                        src="appstore.png"
                        loading="lazy"
                        alt=""
                      />
                    </picture>
                  </a>
                </Col>

                {/* <Image
                  src="playstore.png"
                  rounded
                  // className="h-100"
                  onClick={() => {
                    window.open(google_link, "_blank");
                    console.log("google link clicked");
                  }}
                />

                <Image
                  src="appstore.png"
                  rounded
                  onClick={() => {
                    window.open(ios_link, "_blank");
                    console.log("google link clicked");
                  }}
                /> */}
              </Row>
              <Row className="text-center">
                <a
                  href="https://www.anzali.io"
                  style={{
                    color: "#f0c445",
                    textShadow: "lightgrey 0px 1px",
                    marginTop: "10px",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Launch Web App
                </a>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Top;
