import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./Base.css";
const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", delay: 0.5 },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

const nextVariants = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: 0,
    transition: { type: "spring", stiffness: 120 },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    textShadow: "0px 0px 8px rgb(255,255,255)",
    boxShadow: "0px 0px 8px rgb(255,255,255)",
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
};

const Base = ({ addBase, pizza }) => {
  const bases = ["Classic", "Thin & Crispy", "Thick Crust"];

  // const [isMobile, setMobile] = useState(
  //   window.screen.orientation.type === "portrait-primary"
  // );

  // const updateScreenSize = () => {
  //   setMobile(window.screen.orientation.type === "portrait-primary");
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", updateScreenSize);
  //   return () => window.removeEventListener("resize", updateScreenSize);
  // });

  return (
    // <>
    //   {isMobile ? (
    //     <h1>this is mobile</h1>
    //   ) : (
    <div className="base">
      <motion.div
        className="container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h3>Choose items from the list</h3>
        <ul>
          {bases.map((base) => {
            // let spanClass = pizza.base === base ? "active" : "";
            return (
              <motion.li
                key={base}
                onClick={() => {
                  // addBase(base);
                  console.log(base);
                }}
                whileHover={{ scale: 1.3, originX: 0, color: "#f8e112" }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <span className={""}>{base}</span>
              </motion.li>
            );
          })}
        </ul>
        {/* 
      {pizza.base && (
        <motion.div className="next" variants={nextVariants}>
          <Link to="/toppings">
            <motion.button variants={buttonVariants} whileHover="hover">
              Next
            </motion.button>
          </Link>
        </motion.div>
      )} */}
        <motion.div className="next" variants={nextVariants}>
          <Link to="#">
            <motion.button variants={buttonVariants} whileHover="hover">
              Next
            </motion.button>
          </Link>
        </motion.div>{" "}
      </motion.div>
    </div>
    //   )}
    // </>
  );
};

export default Base;
