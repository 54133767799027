import React from "react";
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import Contact from "../../Contact";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};
// eslint-disable-next-line
const flip = {
  hidden: {
    transform: "scale(0) rotateX(-360deg)",
    opacity: 0,
    transition: {
      delay: 0.3,
    },
  },
  visible: {
    transform: " scale(1) rotateX(0deg)",
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    transform: "scale(0) rotateX(360deg)",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const Modal_Box = ({ handleClose, text }) => {
  return (
    <Backdrop onClick={handleClose} children={"ok"}>
      <div>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          className="modal grey-gradient"
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <p>{text}</p>

          <picture>
            <source srcSet="close-icon.webp" type="image/webp" />
            <source srcSet="close-icon.png" type="image/png" />
            <img
              src="close-icon.png"
              alt="close-icon"
              className="close-icon"
              onClick={handleClose}
            />
          </picture>
          <Contact />
        </motion.div>
      </div>
    </Backdrop>
  );
};

export default Modal_Box;
