import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactFloaterJs from "./ReactFloaterJS";

const FloaterDemo = () => {
  return (
    <div>
      <h1 className="text-center my-5">Floating items</h1>
      <Container>
        <Row className="align-items-center">
          <Col md={4}>
            <ReactFloaterJs>
              <img src="https://picsum.photos/300" alt="" className="my-5 " />
            </ReactFloaterJs>
          </Col>
          <Col>
            <h1 className="mx-3">{"<-"} A floating element</h1>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={6}>
            <h1 className="mx-3"> Another floating element {"->"}</h1>
          </Col>
          <Col>
            <ReactFloaterJs>
              <img
                src="https://picsum.photos/400/300"
                alt=""
                className="my-5 "
              />
            </ReactFloaterJs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FloaterDemo;
